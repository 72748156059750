/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { ExtraFormDataOverride, QueryObject } from './types';
import {
	EXTRA_FORM_DATA_OVERRIDE_EXTRA_KEYS,
	EXTRA_FORM_DATA_OVERRIDE_REGULAR_MAPPINGS,
} from './constants';

export function overrideExtraFormData(
	queryObject: QueryObject,
	overrideFormData: ExtraFormDataOverride,
): QueryObject {
	const overriddenFormData: QueryObject = { ...queryObject };
	const { extras: overriddenExtras = {} } = overriddenFormData;
	Object.entries(EXTRA_FORM_DATA_OVERRIDE_REGULAR_MAPPINGS).forEach(
		([key, target]) => {
			const value = overrideFormData[key as keyof ExtraFormDataOverride];
			if (value !== undefined) {
				overriddenFormData[target] = value;
			}
		},
	);
	EXTRA_FORM_DATA_OVERRIDE_EXTRA_KEYS.forEach(key => {
		if (key in overrideFormData) {
			// @ts-ignore
			overriddenExtras[key] = overrideFormData[key];
		}
	});
	if (Object.keys(overriddenExtras).length > 0) {
		overriddenFormData.extras = overriddenExtras;
	}
	return overriddenFormData;
}
